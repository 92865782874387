export class AnimatedText
{
    constructor(store)
    {
        this.store = store

        this.animatedTexts = document.querySelectorAll('[data-s~="AnimatedText"]')
    }

    show(e)
    {
        let background = e.querySelector('[data-s~="AnimatedText:background"]')
        let foreground = e.querySelector('[data-s~="AnimatedText:foreground"]')
        let title = e.querySelector('[data-s~="AnimatedText:title"]')

        setTimeout(() => {
            background.style.width = "120%"
            foreground.style.width = "120%"

            setTimeout(() => {
                background.style.width = "100%"
                foreground.style.width = "0%"

                setTimeout(() => {
                    title.style.visibility = "visible"
                    title.style.transform = "translateY(0px)"
                    title.style.opacity = "1"

                    setTimeout(() => {
                        e.setAttribute("data-end", "true")
                    }, 500)
                }, 1000)
            }, 1000)
        }, 0)
    }

    hide(e)
    {
        let background = e.querySelector('[data-s~="AnimatedText:background"]')
        let foreground = e.querySelector('[data-s~="AnimatedText:foreground"]')
        let title = e.querySelector('[data-s~="AnimatedText:title"]')

        background.style.width = null
        foreground.style.width = null

        title.style.visibility = null
        title.style.transform = null
        title.style.opacity = null

        setTimeout(() => {
            e.setAttribute("data-start", "true")
            this.check()
        }, 1000)
    }

    check()
    {
        this.animatedTexts.forEach((e) => {
            let start = (e.getAttribute("data-start") === "true") ? true : false
            let end = (e.getAttribute("data-end") === "true") ? true : false

            if ($(e).visible()) {
                if (start) {
                    e.setAttribute("data-start", "false")
                    this.show(e)
                }
            } else {
                if (end) {
                    e.setAttribute("data-end", "false")
                    this.hide(e)
                }
            }
        })
    }

    watch()
    {
        addEventListener("load", () => {
			this.check()
		})
		addEventListener("scroll", () => {
			this.check()
		})
		addEventListener("resize", () => {
			this.check()
		})
    }
}
