import { Controller } from "../Application/Controller.js"
import { News } from "../Service/News.js"
import { Curiosities } from "../Service/Curiosities.js"
import { Reviews } from "../Service/Reviews.js"
import { Reveal } from "../Service/Reveal.js"
import { BackgroundColorEffect } from "../Service/BackgroundColorEffect.js"
import { Invoice } from "../Service/Invoice.js"
import { ScrollDown } from "../Service/ScrollDown.js"
import { AnimatedText } from "../Service/AnimatedText.js"

export class HomeController extends Controller
{
    run()
    {
        this.store.service.news = new News(this.store)
        this.store.service.news.run()

        this.store.service.curiosities = new Curiosities(this.store)
        this.store.service.curiosities.run()

        this.store.service.reviews = new Reviews(this.store)
        this.store.service.reviews.run()

        this.store.service.reveal = new Reveal(this.store)
        this.store.service.reveal.run()

        this.store.service.backgroundColorEffect = new BackgroundColorEffect(this.store)
        this.store.service.backgroundColorEffect.watch()

        this.store.service.invoice = new Invoice(this.store)
        this.store.service.invoice.run()

        this.store.service.scrollDown = new ScrollDown(this.store)
        this.store.service.scrollDown.watch()

        this.store.service.animatedText = new AnimatedText(this.store)
        this.store.service.animatedText.watch()
    }
}
